<template>
    <div>
        
        <v-btn @click="updateLottoSetting"
                class="mr-2"
                small
                color="warning"
                dark
              >
                <v-icon
                small
                @click="updateLottoSetting"
                v-if="checkUserPermission('EDIT')">
            mdi-pencil
        </v-icon>
        </v-btn>

        <v-btn @click="updateLottoSettingPercent"
                small
                color="success"
                dark
              >
                Win Loose Sitting
        </v-btn>

    </div>
</template>

<script>
    import { mapActions } from "vuex";


    export default {
        props: ['darkmode', "item", "params", "dataDelete", "userPermission"],
        data() {
            return {
                loading: false,
                dialogDelete: false,
                userChild: [],
                data1: {
                    descending: true,
                    sortBy: null,
                    page: 1,
                    rowsPerPage: 10,
                    fields: ["name", "is_enable", "is_start", "updatedAt"],
                    search: ''
                }
            };
        },
        computed: {
            
        },
        methods: {
            async updateLottoSetting() {
                
                await this.fetchCurrentLottoSettingData(this.item);
                this.fetchPopUp(true);
            },

            async updateLottoSettingPercent() {
                
                await this.fetchCurrentLottoSettingData(this.item);
                this.fetchPopUpPercent(true);
            },
            ...mapActions("$_lottoPP78Setting", ["fetchPopUp", "fetchPopUpPercent", "fetchCurrentLottoSettingData", "fetchGetAllLottoSetting",]),
        },
    };
</script>
